import axios from 'axios';
import { server } from "../../server";

const getConfig = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };
};

// Create transaction
export const createTransaction = (transactionData) => async (dispatch) => {
  dispatch({ type: "CreateTransactionRequest" });
  try {
    const { data } = await axios.post(
      `${server}/retrait-depot/create`,
      transactionData,
      getConfig()
    );
    dispatch({ type: "CreateTransactionSuccess", payload: data.data });
    return Promise.resolve(data.data);
  } catch (error) {
    dispatch({
      type: "CreateTransactionFailure",
      payload: error.response?.data?.error || 'An error occurred'
    });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

// Fetch transactions
// ... (previous imports remain the same)

export const fetchTransactions = (filters = {}) => async (dispatch) => {
    dispatch({ type: "FetchTransactionsRequest" });
    try {
      // Convert filters to query string, handling the 'all' case properly
      const queryParams = new URLSearchParams();
      Object.entries(filters).forEach(([key, value]) => {
        if (value && value !== 'all') {
          queryParams.append(key, value);
        }
      });
  
      const { data } = await axios.get(
        `${server}/retrait-depot?${queryParams.toString()}`,
        getConfig()
      );
  
      dispatch({
        type: "FetchTransactionsSuccess",
        payload: {
          transactions: data.data,
          summaries: data.summaries
        }
      });
      return Promise.resolve(data);
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'An error occurred';
      dispatch({ type: "FetchTransactionsFailure", payload: errorMessage });
      return Promise.reject(errorMessage);
    }
  };
  


// Update transaction
export const updateTransaction = (id, transactionData) => async (dispatch) => {
  dispatch({ type: "UpdateTransactionRequest" });
  try {
    const { data } = await axios.put(
      `${server}/retrait-depot/${id}`,
      transactionData,
      getConfig()
    );
    dispatch({ type: "UpdateTransactionSuccess", payload: data.data });
    return Promise.resolve(data.data);
  } catch (error) {
    dispatch({
      type: "UpdateTransactionFailure",
      payload: error.response?.data?.error || 'An error occurred'
    });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

// Delete transaction
export const deleteTransaction = (id) => async (dispatch) => {
  dispatch({ type: "DeleteTransactionRequest" });
  try {
    await axios.delete(`${server}/retrait-depot/${id}`, getConfig());
    dispatch({ type: "DeleteTransactionSuccess", payload: id });
    return Promise.resolve();
  } catch (error) {
    dispatch({
      type: "DeleteTransactionFailure",
      payload: error.response?.data?.error || 'An error occurred'
    });
    return Promise.reject(error.response?.data?.error || 'An error occurred');
  }
};

// Add these new actions
export const fetchDailyRetraits = (shopId) => async (dispatch) => {
  dispatch({ type: "FetchDailyRetraitsRequest" });
  try {
    const { data } = await axios.get(
      `${server}/retrait-depot?shop=${shopId}`,
      getConfig()
    );
    dispatch({
      type: "FetchDailyRetraitsSuccess",
      payload: data.summaries.daily
    });
    return Promise.resolve(data.summaries.daily);
  } catch (error) {
    dispatch({
      type: "FetchDailyRetraitsFailure",
      payload: error.response?.data?.error || 'An error occurred'
    });
    return Promise.reject(error);
  }
};

export const fetchMonthlyRetraits = (shopId) => async (dispatch) => {
  dispatch({ type: "FetchMonthlyRetraitsRequest" });
  try {
    const { data } = await axios.get(
      `${server}/retrait-depot?shop=${shopId}`,
      getConfig()
    );
    dispatch({
      type: "FetchMonthlyRetraitsSuccess", 
      payload: data.summaries.monthly
    });
    return Promise.resolve(data.summaries.monthly);
  } catch (error) {
    dispatch({
      type: "FetchMonthlyRetraitsFailure",
      payload: error.response?.data?.error || 'An error occurred'
    });
    return Promise.reject(error);
  }
};