import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Stack,
} from "@mui/material";
import {
  MonetizationOn,
  CreditCard,
  Warning,
  Store,
  CalendarToday,
  AccountBalance,
  TrendingUp,
  Person,
  Inventory,
  PictureAsPdf,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCashSales,
  fetchBankSales,
  fetchCreditSales,
  fetchDebtClients,
  fetchDailyPriceAchat,
  fetchMonthlyPriceAchat,
  fetchDailySales,
  fetchMonthlySales,
} from "../../redux/actions/sales";
import { fetchAllShops, selectShop } from "../../redux/actions/shop";
import {
  fetchDailyPurchasePriceAchat,
  fetchDailyPurchaseSales,
  fetchMonthlyPurchasePriceAchat,
  fetchMonthlyPurchaseSales,
  fetchPurchasesByMethod,
} from "../../redux/actions/purchaseAlimentation";
import moment from "moment";
import {
  generateDailySalesReport,
  generateMonthlySalesReport,
} from "../../utils/reportGenerator";
import { toast } from "react-toastify";
import {
  fetchDailyDepenses,
  fetchMonthlyDepenses,
} from "../../redux/actions/depense";
import { getTotals } from "../../services/API";
import { fetchDailyRetraits, fetchMonthlyRetraits } from "../../redux/actions/retraitDepot";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { shops } = useSelector((state) => state.shop);
  const {
    cashSales,
    bankSales,
    creditSales,
    debtClients,
    dailyPriceAchat,
    monthlyPriceAchat,
    dailySales,
    monthlySales,
  } = useSelector((state) => state.sales);
  const {
    depenses,
    dailyDepenses,
    monthlyDepenses,
    dailyTotalDepenses,
    monthlyTotalDepenses,
    loading,
    error,
  } = useSelector((state) => state.depense);
  const {
    cashPurchases,
    creditPurchases,
    dailyPurchasePriceAchat,
    monthlyPurchasePriceAchat,
    dailyPurchaseSales,
    monthlyPurchaseSales,
  } = useSelector((state) => state.purchaseAlimentation);
  const { dailyRetraits, monthlyRetraits } = useSelector((state) => state.retraitDepot);
  const [selectedShop, setSelectedShop] = useState("");
  const [shopSelected, setShopSelected] = useState("");
  const [stats, setStats] = useState({
    daily: {
      total: 0,
      cash: 0,
      bank: 0,
      credit: 0,
    },
    overall: {
      total: 0,
      cash: 0,
      bank: 0,
      credit: 0,
      pending: 0,
    },
  });

  // Filter shops based on type
  const electronicShops = shops.filter((shop) => shop.type === "electronics");
  const alimentationShops = shops.filter(
    (shop) => shop.type === "alimentation"
  );

  useEffect(() => {
    if (user?.role === "superadmin") {
      dispatch(fetchAllShops());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (selectedShop) {
      dispatch(fetchCashSales(selectedShop));
      dispatch(fetchBankSales(selectedShop));
      dispatch(fetchDailyDepenses(selectedShop));
      dispatch(fetchMonthlyDepenses(selectedShop));
      dispatch(fetchCreditSales(selectedShop));
      dispatch(fetchDebtClients(selectedShop));
      dispatch(fetchDailyPriceAchat(selectedShop));
      dispatch(fetchMonthlyPriceAchat(selectedShop));
      dispatch(fetchDailySales(selectedShop));
      dispatch(fetchMonthlySales(selectedShop));
      dispatch(fetchDailyRetraits(selectedShop));
      dispatch(fetchMonthlyRetraits(selectedShop));
      const selectedShopData = shops.find((s) => s._id === selectedShop);
      setShopSelected(selectedShopData);
      if (selectedShopData?.type === "alimentation") {
        dispatch(fetchPurchasesByMethod("cash", selectedShop));
        dispatch(fetchPurchasesByMethod("credit", selectedShop));
        dispatch(fetchDailyPurchasePriceAchat(selectedShop));
        dispatch(fetchMonthlyPurchasePriceAchat(selectedShop));
        dispatch(fetchDailyPurchaseSales(selectedShop));
        dispatch(fetchMonthlyPurchaseSales(selectedShop));
      }
    }
  }, [dispatch, selectedShop, shops]);

  useEffect(() => {
    if (cashSales && bankSales && creditSales) {
      const today = moment().startOf("day");
      const selectedShopData = shops.find((s) => s._id === selectedShop);

      // Calculate daily stats
      const todayCashSales =
        selectedShopData?.type === "alimentation"
          ? [...cashSales, ...cashPurchases]
              .filter((sale) => moment(sale.createdAt).isSame(today, "day"))
              .reduce((sum, sale) => sum + sale.total_amount, 0)
          : cashSales
              .filter((sale) => moment(sale.createdAt).isSame(today, "day"))
              .reduce((sum, sale) => sum + sale.total_amount, 0);

      const todayCreditSales =
        selectedShopData?.type === "alimentation"
          ? [...creditSales, ...creditPurchases]
              .filter((sale) =>
                sale.payment_history.some((payment) =>
                  moment(payment.payment_date).isSame(today, "day")
                )
              )
              .reduce((sum, sale) => sum + sale.amount_paid, 0)
          : creditSales
              .filter((sale) =>
                sale.payment_history.some((payment) =>
                  moment(payment.payment_date).isSame(today, "day")
                )
              )
              .reduce((sum, sale) => sum + sale.amount_paid, 0);

      const todayBankSales = bankSales
        .filter((sale) => moment(sale.createdAt).isSame(today, "day"))
        .reduce((sum, sale) => sum + sale.total_amount, 0);

      // Calculate overall stats
      const totalCashSales =
        selectedShopData?.type === "alimentation"
          ? [...cashSales, ...cashPurchases].reduce(
              (sum, sale) => sum + sale.total_amount,
              0
            )
          : cashSales.reduce((sum, sale) => sum + sale.total_amount, 0);

      const totalCreditSales =
        selectedShopData?.type === "alimentation"
          ? [...creditSales, ...creditPurchases].reduce(
              (sum, sale) => sum + sale.amount_paid,
              0
            )
          : creditSales.reduce((sum, sale) => sum + sale.amount_paid, 0);

      const totalBankSales = bankSales.reduce(
        (sum, sale) => sum + sale.total_amount,
        0
      );

      const totalPendingPayments =
        selectedShopData?.type === "alimentation"
          ? [...creditSales, ...creditPurchases].reduce(
              (sum, sale) => sum + sale.remaining_amount,
              0
            )
          : creditSales.reduce((sum, sale) => sum + sale.remaining_amount, 0);

      setStats({
        daily: {
          total: todayCashSales + todayCreditSales + todayBankSales,
          cash: todayCashSales,
          bank: todayBankSales,
          credit: todayCreditSales,
        },
        overall: {
          total: totalCashSales + totalCreditSales + totalBankSales,
          cash: totalCashSales,
          bank: totalBankSales,
          credit: totalCreditSales,
          pending: totalPendingPayments,
        },
      });
    }
  }, [cashSales, bankSales, creditSales, selectedShop, shops]);

  const [totalAlimentation, setTotalAlimentation] = useState(0);
  const [totalElectronicsFirstShop, setTotalElectronicsFirstShop] = useState(0);
  const [totalElectronicsSecondShop, setTotalElectronicsSecondShop] =
    useState(0);

  const fetchAndUseTotals = async () => {
    try {
      const {
        totalAlimentation,
        totalElectronicsFirstShop,
        totalElectronicsSecondShop,
      } = await getTotals();

      // console.log("Total Alimentation:", totalAlimentation);
      // console.log("Total Electronics (First Shop):", totalElectronicsFirstShop);
      // console.log("Total Electronics (Second Shop):", totalElectronicsSecondShop);
      setTotalAlimentation(totalAlimentation);
      setTotalElectronicsFirstShop(totalElectronicsFirstShop);
      setTotalElectronicsSecondShop(totalElectronicsSecondShop);

      // Use the totals directly in your logic
    } catch (error) {
      console.error("Error fetching totals:", error);
    }
  };

  useEffect(() => {
    fetchAndUseTotals();
  }, []);

  const StatCard = ({ title, value, icon, color }) => (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography color="textSecondary" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h5" component="div">
              {value?.toLocaleString()} Fbu
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: color,
              borderRadius: "50%",
              padding: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
  const StatCardDebt = ({ title, value, icon, color }) => (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography color="textSecondary" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h5" component="div">
              {value?.toLocaleString()} Clients
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: color,
              borderRadius: "50%",
              padding: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {icon}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  if (user?.role !== "superadmin") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <Typography variant="h5">Access Denied</Typography>
      </Box>
    );
  }

  return (
    <Box m="20px">
      {/* Header with Shop Selection */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h4">Dashboard Aperçu</Typography>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Select Shop</InputLabel>
          <Select
            value={selectedShop}
            onChange={(e) => setSelectedShop(e.target.value)}
            label="Select Shop"
          >
            <MenuItem value="">
              <em>Select a shop</em>
            </MenuItem>
            {/* Electronics Shops */}
            {electronicShops.length > 0 && [
              <MenuItem
                key="electronics-header"
                disabled
                sx={{ color: "text.secondary" }}
              >
                Electronics Shops
              </MenuItem>,
              ...electronicShops.map((shop) => (
                <MenuItem key={shop._id} value={shop._id}>
                  {shop.name}
                </MenuItem>
              )),
            ]}
            {/* Alimentation Shops */}
            {alimentationShops.length > 0 && [
              <MenuItem
                key="alimentation-header"
                disabled
                sx={{ color: "text.secondary" }}
              >
                Alimentation Shops
              </MenuItem>,
              ...alimentationShops.map((shop) => (
                <MenuItem key={shop._id} value={shop._id}>
                  {shop.name}
                </MenuItem>
              )),
            ]}
          </Select>
        </FormControl>
      </Box>

      {/* Add after shop selection and before stats */}
      <Box display="flex" justifyContent="flex-end" mb={3}>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            startIcon={<PictureAsPdf />}
            onClick={() => {
              if (!selectedShop) {
                toast.error("Veuillez d'abord sélectionner une boutique");
                return;
              }
              const shop = shops.find((s) => s._id === selectedShop);
              const dailyStats = {
                type: shop.type,
                dailySales,
                dailyPurchaseSales,
                dailyPriceAchat,
                dailyPurchaseSales,
                dailyPurchasePriceAchat,
                dailyDepenses: dailyDepenses?.totalAmount || 0,
              };
              generateDailySalesReport(
                cashSales,
                bankSales,
                creditSales,
                shop.name,
                dailyStats
              );
            }}
          >
            Rapport du jour
          </Button>

          <Button
            variant="contained"
            startIcon={<PictureAsPdf />}
            onClick={() => {
              if (!selectedShop) {
                toast.error("Veuillez d'abord sélectionner une boutique");
                return;
              }
              const shop = shops.find((s) => s._id === selectedShop);
              const monthlyStats = {
                type: shop.type,
                monthlySales,
                monthlyPurchaseSales,
                monthlyPriceAchat,
                monthlyPurchasePriceAchat,
                monthlyDepenses: monthlyDepenses?.totalAmount || 0,
              };
              const capitals = {
                totalElectronicsFirstShop,
                totalElectronicsSecondShop,
                totalAlimentation,
              };
              generateMonthlySalesReport(
                cashSales,
                bankSales,
                creditSales,
                shop.name,
                monthlyStats,
                capitals
              );
            }}
          >
            Rapport du Mois
          </Button>
        </Stack>
      </Box>

      {selectedShop ? (
        <>
          {/* Daily Stats */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Ventes d'aujourd'hui
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Ventes totales"
                  value={stats.daily.total}
                  icon={<TrendingUp sx={{ color: "white" }} />}
                  color="#4caf50"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Ventes en Cash"
                  value={stats.daily.cash}
                  icon={<MonetizationOn sx={{ color: "white" }} />}
                  color="#2196f3"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Ventes bancaires"
                  value={stats.daily.bank}
                  icon={<AccountBalance sx={{ color: "white" }} />}
                  color="#ff9800"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Ventes à crédit paye"
                  value={stats.daily.credit}
                  icon={<CreditCard sx={{ color: "white" }} />}
                  color="#f44336"
                />
              </Grid>
            </Grid>
          </Paper>

          {/* Daily and monthly expenses remember to add if alimentation */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Les depenses et interets
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Depense total aujourd'hui"
                  value={dailyDepenses?.totalAmount}
                  icon={<CreditCard sx={{ color: "white" }} />}
                  color="#f44336"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Depense total mensuel"
                  value={monthlyDepenses?.totalAmount}
                  icon={<CreditCard sx={{ color: "white" }} />}
                  color="#f44336"
                />
              </Grid>
              {/* les interets selon le shop */}

              {shopSelected?.type === "electronics" ? (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                      title="Interet d'aujourdh'ui"
                      value={Number(
                        Number(dailySales) - Number(dailyDepenses?.totalAmount)
                      )}
                      icon={<MonetizationOn sx={{ color: "white" }} />}
                      color="#2196f3"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                      title="Interet mensuel"
                      value={Number(
                        Number(monthlySales) -
                          Number(monthlyDepenses?.totalAmount)
                      )}
                      icon={<MonetizationOn sx={{ color: "white" }} />}
                      color="#2196f3"
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                      title="Interet d'aujourdh'ui"
                      value={Number(
                        Number(dailyPurchaseSales) -
                          Number(dailyDepenses?.totalAmount)
                      )}
                      icon={<MonetizationOn sx={{ color: "white" }} />}
                      color="#2196f3"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <StatCard
                      title="Interet mensuel"
                      value={Number(
                        Number(monthlyPurchaseSales) -
                          Number(monthlyDepenses?.totalAmount)
                      )}
                      icon={<MonetizationOn sx={{ color: "white" }} />}
                      color="#2196f3"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Paper>

          {/* Overall Stats */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Statistiques globales
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <StatCard
                      title="Chiffre d'affaires total"
                      value={stats.overall.total}
                      icon={<TrendingUp sx={{ color: "white" }} />}
                      color="#4caf50"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StatCard
                      title="Paiements en attente"
                      value={stats.overall.pending}
                      icon={<Warning sx={{ color: "white" }} />}
                      color="#f44336"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper sx={{ p: 2, height: "100%" }}>
                  <Typography variant="h6" gutterBottom>
                    Répartition des paiements
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <MonetizationOn />
                      </ListItemIcon>
                      <ListItemText
                        primary="Vente en Cash"
                        secondary={`${stats.overall.cash.toLocaleString()} Fbu`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <AccountBalance color="warning" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Ventes bancaires"
                        secondary={`${stats.overall.bank.toLocaleString()} Fbu`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CreditCard color="error" />
                      </ListItemIcon>
                      <ListItemText
                        primary="Ventes à crédit"
                        secondary={`${stats.overall.credit.toLocaleString()} Fbu`}
                      />
                    </ListItem>
                  </List>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
           {/* Retrait Statistics */}
           <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Statistiques des Retraits 
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Retraits du jour"
                  value={dailyRetraits?.withdrawals || 0}
                  icon={<MonetizationOn sx={{ color: "white" }} />}
                  color="#f44336"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Vente restante du jour"
                  value={Number(Number(stats.daily.total)-Number(dailyRetraits?.withdrawals)) || 0}
                  icon={<MonetizationOn sx={{ color: "white" }} />}
                  color="#4caf50"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Retraits du mois"
                  value={monthlyRetraits?.withdrawals || 0}
                  icon={<MonetizationOn sx={{ color: "white" }} />}
                  color="#f44336"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Chiffre d'affaires restant ce mois(apres retraits)"
                  value={Number(Number(stats.overall.total)-Number(monthlyRetraits?.withdrawals)) || 0}
                  icon={<MonetizationOn sx={{ color: "white" }} />}
                  color="#4caf50"
                />
              </Grid>
            </Grid>
          </Paper>

          {/* Debt Clients Summary */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Statut de crédit
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <StatCardDebt
                  title="Total des créditeurs"
                  value={debtClients?.length || 0}
                  icon={<Person sx={{ color: "white" }} />}
                  color="#9c27b0"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Total des créditeurs en attente"
                  value={stats.overall.pending}
                  icon={<Warning sx={{ color: "white" }} />}
                  color="#f44336"
                />
              </Grid>
            </Grid>
          </Paper>
          {/* Les capitaux */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Les capitaux initiaux
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Capital dans le shop 1"
                  value={totalElectronicsFirstShop}
                  icon={<TrendingUp sx={{ color: "white" }} />}
                  color="#4caf50"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Capital dans le shop 2"
                  value={totalElectronicsSecondShop}
                  icon={<TrendingUp sx={{ color: "white" }} />}
                  color="#2196f3"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Capital dans le shop 3"
                  value={totalAlimentation}
                  icon={<TrendingUp sx={{ color: "white" }} />}
                  color="#ff9800"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <StatCard
                  title="Total des capitaux"
                  value={Number(
                    Number(totalAlimentation) +
                      Number(totalElectronicsSecondShop) +
                      Number(totalElectronicsFirstShop)
                  )}
                  icon={<TrendingUp sx={{ color: "white" }} />}
                  color="#f44336"
                />
              </Grid>
            </Grid>
          </Paper>


        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <Typography variant="h6" color="textSecondary">
            Veuillez sélectionner une boutique pour voir les statistiques
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Dashboard;
